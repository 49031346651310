footer {
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: #c7d5da;
    display: flex;
    justify-content: center;
    font-family: 'Open Sans',sans-serif;
}

.footer_wrap {
    position: relative;
    max-width: 1294px;
    width: 1294px;
    display: flex;
    flex-direction: column;
}

.footer_white_space_big {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 20px;
    max-height: 20px;
}

.footer_white_space_little {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 10px;
    max-height: 10px;
}


/* footer top info */
.footer_top_info {
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer_top_info_left,
.footer_top_info_right {
    position: relative;
    display: flex;
    flex-direction: column;
}

/* footer top left */

.footer_top_info_left_nav,
.footer_top_info_left_nav nav {
    position: relative;
}

.footer_top_info_left_nav ul {
    list-style: none;
    position: relative;
    display: flex;
}

.footer_top_info_left_nav ul>li {
    margin-left: 5px;
}
.footer_top_info_left_nav ul>li:first-child {
    margin-left: 0;
}

.footer_top_info_left_nav ul>li a {
    text-decoration: none;
    color: #000;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1.2;
}

.footer_top_info_left_nav ul>li a:hover {
    color: #727379;
}

.footer_top_info_left p {
    font-size: 11px;
}

.footer_top_info_social_nav,
.footer_top_info_social_nav nav {
    position: relative;
}

.footer_top_info_social_nav ul {
    position: relative;
    list-style: none;
    display: flex;
}

.footer_top_info_social_nav li {
    margin-left: 10px;
    width: 25px;
    max-width: 25px;
    height: 25px;
    max-height: 25px;
}

.footer_top_info_social_nav li:first-child {
    margin-left: 0;
}

.footer_top_info_social_nav a {
    width: 25px;
    max-width: 25px;
    height: 25px;
    max-height: 25px;
}

.footer_top_info_social_nav svg {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}

.footer_svg_facebook:hover svg {
    fill: #3b5998;
}

.footer_svg_twitter:hover svg {
    fill: #1da1f2;
}

.footer_svg_instagram:hover svg {
    fill: #e1306c;
}

.footer_svg_pinterest:hover svg {
    fill: #e60023;
}

.footer_svg_yelp:hover svg {
    fill: #c41200;
}

.footer_svg_theknot:hover svg {
    fill: #088f8f;
}

.footer_svg_youtube:hover svg {
    fill: red;
}

/* footer top right */
.footer_phone {
    position: relative;
}

.footer_phone a {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    gap: 5px;
    justify-content: flex-end;
}

.footer_phone span {
    font-size: 16px;
    line-height: 1;
}

.footer_top_info_right_aura {
    position: relative;
}

.footer_top_info_right_aura p {
    text-align: right;
    font-size: 11px;
}

.footer_stars {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.footer_rating {
    position: relative;
}

.footer_rating p {
    text-align: right;
    font-size: 11px;
}

.footer_rating a {
    text-decoration: none;
    color: #000;
}

.footer_top_info_right>div:last-child {
    position: relative;
}

.footer_top_info_right>div:last-child p {
    font-size: 12px;
    text-align: right;
}

/* footer bootom info */
.footer_bottom_info {
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.footer_bottom_info_item {
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.footer_bottom_info_item p, .footer_bottom_info_item span {
    font-size: 11px;

}

.footer_bottom_info_item a {
    text-decoration: none;
    color: #000;
}


@media (max-width: 1680px) {
    .footer_wrap {
        width: 75%;
        max-width: 75%;
    }
}

@media (max-width: 1440px) {
    .footer_wrap {
        width: 80%;
        max-width: 80%;
    }
}

@media (max-width: 1366px) {
    .footer_wrap {
        width: 83%;
        max-width: 83%;
    }
}

@media (max-width: 1280px) {
    .footer_wrap {
        width: 97%;
        max-width: 97%;
    }
}

@media (max-width: 900px) {

}

@media (max-width: 850px) {
    .footer_top_info {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .footer_top_info_left {
        width: 100%;
        max-width: 100%;
    }

    .footer_top_info_left_nav ul {
        justify-content: space-between;
    }

    .footer_top_info_left_nav ul>li {
        margin-left: 0;
    }

    .footer_top_info_left p {
        text-align: center;
    }

    .footer_top_info_social_nav nav {
        display: flex;
        justify-content: center;
    }

    .footer_top_info_right {
        width: 100%;
        max-width: 100%;
    }

    .footer_phone a {
        justify-content: center;
    }

    .footer_top_info_right_aura p {
        text-align: center;
    }

    .footer_stars {
        justify-content: center;
    }

    .footer_rating p {
        text-align: center;
    }

    .footer_top_info_right>div:last-child p {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .footer_wrap {
        width: 92%;
        max-width: 92%;
    }

    .footer_top_info_left_nav ul {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

}
