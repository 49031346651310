p,h1,h2,h3,h4,h5,h6,span,a,ul,li {
    padding: 0 !important;
}

a, a:active, a:focus, a:hover {
    color: #000;
    outline: 0;
    text-decoration: none;
}

/* seo text */
.seo_text, .main_page_map,
.what_we_do, .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.seo_text_wrap, .main_page_map_wrap,
.what_we_do_wrap, .carousel_wrap, .footer_wrap {
    width: 70%;
}

.seo_text_wrap h1{
    font-size: 20px;
    font-weight: bold;
    font-family: 'Open Sans',sans-serif;
    color: #333333;
}

.seo_text_wrap a {
    text-decoration: none;
    color: #000;
    padding: 0 !important;
}


.seo_text_wrap p {
    margin-top: 10px;
    font-family: 'Open Sans',sans-serif;
    font-size: 13px;
    padding: 0 !important;
    line-height: 22px;
}

.seo_text_wrap h2 {
    margin-top: 10px;
    font-family: 'Open Sans',sans-serif;
    font-size: 18px;
    padding: 0 !important;
}

.seo_text_wrap h3{
    margin-top: 10px;
    font-family: 'Open Sans',sans-serif;
    font-size: 18px;
    padding: 0 !important;
}

.seo_text_wrap ul {
    list-style-type: inherit;
    list-style-position: inside;
    list-style: none;
}

.seo_text_wrap ul>li>span {
    font-family: 'Open Sans',sans-serif;
    font-size: 13px;
}

/* map */

.main_page_map {
    margin-top: 10px;
    margin-bottom: 55px;
}

.main_page_map_content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main_page_map_content_top {
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
}

.main_page_map_data {
    width: 49%;
}

.main_page_map_info p{
    margin-bottom: 10px;
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
    white-space: nowrap;
}

.main_page_map_content_bottom {
    font-family: 'Open Sans',sans-serif;
}

.main_page_map_hours>.about__hours--title {
    font-size: 18px;
    font-family: 'Open Sans',sans-serif;
    font-weight: 700;
}

.main_page_map_hours>.about__hours--table td{
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
}

/* what wwe do */

.what_we_do_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.what_we_do_line>div {
    width: 32%;
}

.what_we_do_line>div>h3 {
    font-family: 'Open Sans',sans-serif;
    font-size: 18px;
    color: #333333;
    margin-bottom: 19px;
}

.what_we_do_line>div>p {
    font-family: 'Open Sans',sans-serif;
    font-size: 13px;
    line-height: 22px;
}

/* carousel */
.carousel_wrap>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.carousel_wrap>div>h3 {
    font-family: 'Open Sans',sans-serif;
    font-size: 24px;
    color: #333333;
}

.carousel_content {
    width: 100%;
    margin-top: 25px;
    display: flex !important;
    align-items: center;
}

.carousel_content_item_wrap {
    display: flex ;
    flex-direction: column;
    align-items: center;
}

.carousel_content_item_wrap>a>img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.carousel_content_item_wrap>a {
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
}

.carousel_content_item_wrap>a:nth-child(2) {
    margin-top: 40px;
}

.carousel_content_item_wrap>span {
    margin-top: 20px;
    text-align: center;
    font-family: 'Open Sans',sans-serif;
    font-size: 13px;
    width: 90%;
    line-height: 1.5;
}

.carousel-btn {
    cursor: pointer;
    font-size: 30px;
}

.carousel-btn:hover {
    opacity: .4;
}



@media (max-width: 1680px) {
    .seo_text_wrap, .main_page_map_wrap,
    .what_we_do_wrap, .carousel_wrap, .footer_wrap {
        width: 75%;
    }
}

@media (max-width: 1440px) {
    .seo_text_wrap, .main_page_map_wrap,
    .what_we_do_wrap, .carousel_wrap, .footer_wrap {
        width: 80%;
    }
}

@media (max-width: 1366px) {
    .seo_text_wrap, .main_page_map_wrap,
    .what_we_do_wrap, .carousel_wrap, .footer_wrap {
        width: 83%;
    }

    .testimonials--banner {
        height: 230px;
    }
}

@media (max-width: 1280px) {
    .seo_text_wrap, .main_page_map_wrap,
    .what_we_do_wrap, .carousel_wrap, .footer_wrap {
        width: 97%;
    }
}

@media (max-width: 1024px) {
    .main_page_map_content_top {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .main_page_map_data {
        width: 100%;
    }

}


@media (max-width: 500px) {
    .seo_text_wrap, .main_page_map_wrap,
    .what_we_do_wrap, .carousel_wrap, .footer_wrap {
        width: 92%;
    }

    .main_page_map_content_top {
        justify-content: flex-start;
    }

    .what_we_do_line {
        flex-wrap: wrap;
    }

    .what_we_do_line>div {
        width: 100%;
    }

    .what_we_do_line>div>p {
        margin-bottom: 20px;
    }

    .carousel_wrap>div>h3 {
        text-align: center;
    }

    .testimonials--banner-content-wrap {
        width: 100%;
    }

    .testimonials--banner {
        height: 155px;
        background-color: rgba(0, 0, 0, .2);
    }

    .testimonials--banner-content {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .testimonials--banner-content>a {
        color: #fff;
        font-size: 19px;
        letter-spacing: 3px;
    }

    .testimonials--banner-content span {
        font-size: 12px;
        color: #fff;
        font-family: 'Open Sans',sans-serif;
        font-weight: 600;
        line-height: 1.4;
        text-align: center;
    }

    .testimonials--banner-content>div>a {
        color: #fff !important;
        border: 1px solid #fff;
    }
}

@media (min-width: 1921px) {
    .carousel_wrap, 
    .footer_wrap, 
    .main_page_map_wrap, 
    .seo_text_wrap, 
    .what_we_do_wrap {
        width: 1920px;
    }
}
