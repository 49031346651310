/* ------- main --------- */

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}

main, article {
    position: relative;
    width: 100%;
}

.span-header-class {
    line-height: 1;
    font-size: 30px;
    font-weight: 400;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    color: #fff;
    display: block !important;
}

.banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.banner-line {
    display: flex;
    width: 100%;
}

.banner-line>div {
    position: relative;
    width: 50%;
    background-size: cover;
    height: 596px;
    display: flex;
    flex-direction: column;
}

.banner-line>div>a {
    color: #fff;
    text-decoration: none;
    line-height: 1;
    font-size: 30px;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    width: 29%;
    margin-top: 10%;
    letter-spacing: 5px;
    margin-left: 3%;
    margin-right: 3%;
}

.b-loose-diamonds>a,
.b-wedding-rings>a,
.b-custom-design>a {
    text-align: right;
    padding-right: 20px;
}

.b-engagement-rings>a,
.b-fashion-rings>a,
.b-aura-guarantee>a {
    padding-left: 20px;
}

.banner-line>div>div {
    margin-top: 20px;
}

.b-loose-diamonds>div,
.b-wedding-rings>div,
.b-custom-design>div {
    margin-right: 3%;
}

.b-engagement-rings>div,
.b-fashion-rings>div,
.b-aura-guarantee>div {
    margin-left: 3%;
}

.banner-line>div>div>a,
.gift-ideas>div>a {
    color: #fff;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    border: 1px solid #fff;
    width: auto;
    padding: 10px 3px !important;
    text-decoration: none;
}

.banner-line>div>div>a:hover,
.gift-ideas>div>a:hover {
    background-color: #fff;
    color: #000;
}

.b-custom-design span,
.b-aura-guarantee span,
.gift-ideas span {
    color: #fff;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    font-size: 14px;
}

#b-custom-design-span {
    margin-top: 20px;
}

.b-custom-design>span {
    margin-right: 3%;
}

.b-aura-guarantee>span {
    margin-left: 3%;
}

.background-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.background-block img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.b-loose-diamonds {
    align-items: flex-end;
}

.b-wedding-rings {
    align-items: flex-end;
}

.b-custom-design {
    align-items: flex-end;
}

.gift--ideas-banner {
    position: relative;
    width: 100%;
    height: 600px;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.gift-ideas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
}

.gift-ideas h4 {
    line-height: 1;
    font-size: 30px;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-weight: normal;
}

.gift-ideas>div {
    margin-top: 20px;
}

.gift-ideas>div>a {
    width: 73px;
    height: 26px;
    margin: 0 10px;
}

.testimonials--banner {
    position: relative;
    width: 100%;
    height: 300px;
    background-size: cover;
}

.testimonials--banner-content-wrap {
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.testimonials--banner-content {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
}

.testimonials--banner-content>a {
    color: #000;
    text-decoration: none;
    line-height: 1;
    letter-spacing: 10px;
    font-size: 30px;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
}

.testimonials--banner-content>span {
    color: #000;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    font-size: 14px;
}

.testimonials--banner-content>div {
    margin-top: 20px;
}

.testimonials--banner-content>div>a {
    color: #000;
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    border: 1px solid #000;
    width: auto;
    padding: 10px 3px !important;
    text-decoration: none;
}

.testimonials--banner-content>div>a:hover {
    background-color: #000;
    color: #fff;
}

@media (max-width: 1680px) {

    .banner-line>div {
        height: 520px;
    }

    .gift--ideas-banner {
        height: 525px;
    }

    .testimonials--banner {
        height: 263px;
    }

}

@media (max-width: 1440px) {
    .desktop-header-wrap.is-sticky .desktop-header-tel {
        width: 38%;
    }

    .banner-line>div>a {
        width: 31%;
    }

    .testimonials--banner-content-wrap {
        width: 95%;
    }

    .testimonials--banner-content {
        margin-left: 5%;
    }

    .banner-line>div {
        height: 446px;
    }

    .gift--ideas-banner {
        height: 450px;
    }

    .testimonials--banner {
        height: 225px;
    }
}

@media (max-width: 1366px) {
    .banner-line>div {
        height: 423px;
    }

    .gift--ideas-banner {
        height: 427px;
    }

    .testimonials--banner {
        height: 214px;
    }
}

@media (max-width: 1280px) {
    .banner-line>div {
        height: 396px;
    }

    .gift--ideas-banner {
        height: 400px;
    }

    .testimonials--banner {
        height: 200px;
    }
}

@media (max-width: 1024px) {
/*    main {
        top: 80px;
    }*/

    .banner-line>div {
        height: 320px;
    }

    .gift--ideas-banner {
        height: 320px;
    }

    .testimonials--banner {
        height: 160px;
    }
}

@media (max-width: 768px) {
    .banner-line>div {
        height: 240px;
    }

    .gift--ideas-banner {
        height: 240px;
    }

    .testimonials--banner {
        height: 240px !important;
    }

    .banner-line>div>a {
        font-size: 18px;
        width: 51%;
    }

    .gift-ideas h4, .testimonials--banner-content>a {
        font-size: 18px;
    }

    .b-custom-design span, .b-aura-guarantee span, .gift-ideas span,
    .testimonials--banner-content>span {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .b-loose-diamonds, .b-engagement-rings,
    .b-wedding-rings, .b-fashion-rings, .gift--ideas-banner,
    .b-custom-design, .b-aura-guarantee {
        background-color: rgba(0, 0, 0, .2);
    }

    .banner-line {
        flex-wrap: wrap;
    }

    .banner-line>div {
        width: 100%;
    }

    .banner-line>div>a {
        width: 43%;
        margin-top: 15%;
    }

    .b-custom-design span, .b-aura-guarantee span, .gift-ideas span, .testimonials--banner-content>span {
        font-size: 11px;
    }

    .banner-line>div {
        height: 313px;
    }

    .gift--ideas-banner {
        height: 156px;
    }

    .testimonials--banner {
        height: 100px !important;
    }

    .banner-line>div>div>a,
    .testimonials--banner-content>div>a {
        position: absolute;
        top: 0;
        left: 0;
        width: 97%;
        height: 100%;
        opacity: 0;
    }

    #b-custom-design-span,.b-custom-design span, .b-aura-guarantee span, .gift-ideas span, .testimonials--banner-content>span {
        display: none;
    }

    .testimonials--banner-content>a {
        margin-left: 56%;
        font-size: 16px !important;
    }

    .gift-ideas>div {
        margin-top: 6px;
    }

    .gift-ideas>div>a {
        padding: 4px 7px !important;
        margin: 0 5px;
    }

    .banner-line>div {
        height: 244px;
    }

    .gift--ideas-banner {
        height: 122px;
    }

    .testimonials--banner {
        height: 61px !important;
    }

    .testimonials--banner-content>div>a {
        height: 62%;
    }


    .testimonials--banner-content-wrap,
    .testimonials--banner-content {
        height: 100% !important;
        width: 100% !important;
        margin: 0;
        justify-content: center;
        position: relative;
    }

    .testimonials--banner-content>div {
        margin: 0;
    }

}

@media (max-width: 390px) {


}

@media (max-width: 360px) {

    .b-custom-design span, .b-aura-guarantee span, .gift-ideas span, .testimonials--banner-content>span {
        font-size: 10px;
    }

    .banner>div>div>div .background-block,
    .banner>div .background-block {
        display: none;
    }

    .banner-line {
        flex-wrap: nowrap;
        height: 100px;
    }

    .banner-line>div {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }

    .banner-line>div>div>a {
        display: none;
    }

    .banner-line>div>div {
        display: none;
    }

    .banner-line>div>a {
        text-align: center;
        width: 100%;
        margin: 0;
    }

    .gift--ideas-banner {
        width: 100%;
        background-color: #000;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gift-ideas {
        width: 100%;
    }

    .gift-ideas>div {
        height: 26px;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .gift-ideas>div>a {
        height: 100%;
        margin: 0;
        padding: 0 !important;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .b-custom-design>span,
    .b-aura-guarantee>span {
        display: none;
    }

    .banner>.testimonials--banner {
        background-color: #000;
        border-top: 1px solid #fff;
        height: 120px;
    }

    .banner>.testimonials--banner>.testimonials--banner-content-wrap>.testimonials--banner-content>div {
        display: none;
    }

    /* .b-aura-guarantee,
    .b-engagement-rings,
    .b-fashion-rings {
        border-left: 1px solid #fff;
    } */

    .banner>div:nth-child(2) {
        border-top: 1px solid #fff;
    }

    .banner {
        height: 500px;
    }

    .seo_text,
    .seo_text_wrap {
        height: 4600px;
    }

    .main_page_map,
    .main_page_map_wrap,
    .main_page_map_content {
        height: 970px;
    }

    .what_we_do {
        height: 1260px;
    }

    .carousel {
        height: 346px;
    }

    .gift-ideas {
        height: 100%;
        margin: 0;
    }

    .gift-ideas span {
        margin-top: 20px;
    }

    .testimonials--banner-content-wrap {
        height: 100%;
    }

    .testimonials--banner-content {
        height: 100%;
        margin: 0;
    }

    .testimonials--banner-content>a {
        margin-top: 20px;
    }

    .desktop-header,
    .desktop-header-logo {
        height: 70px;
    }

    .desktop-header-logo {
        width: 40%;
    }

    .desktop-header-menu-tel {
        width: 60%;
    }

    .main_page_map_content_top {
        height: 921px;
    }

    .main_page_map_data {
        height: 408px;
    }

    .main_page_map_info {
        height: 279px;
    }

    .main_page_map_hours {
        height: 234px;
    }

    .main_page_map_content_bottom {
        height: 72px;
    }

    .what_we_do_wrap {
        height: 1314px;
    }

    .carousel_wrap {
        height: 346px;
    }

    .carousel_wrap>div {
        height: 346px;
    }

    /* ------------ */

    .white-space {
        display: block;
        min-height: 80px;
        height: 80px;
    }

    main {
        top: auto;
        display: block;
    }

    footer {
        height: 403px;
        top: auto !important;
    }
}

@media (min-width: 1921px) {
    .banner {
        align-items: center;
    }

    .gift--ideas-banner,
    .testimonials--banner,
    .banner-line {
        width: 1920px;
    }
}