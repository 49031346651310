* {
    margin: 0;
    padding: 0;
    /*font: 12px/1.5 "Helvetica Neue",Helvetica,Arial,sans-serif;*/
    line-height: 1.5;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}

a {
    text-decoration: none;
    color: #000;
}


/*.seo {*/
/*    !*margin-top: 110px;*!*/
/*    position: relative;*/
/*    top: 110px;*/
/*}*/

.breadcrumbs {
    width: 100%;
    max-width: 100%;
    height: 26px;
    display: flex;
    align-items: flex-end;
    font-weight: 400!important;
    line-height: 1.25;
    text-transform: uppercase;
}

.item-column-breadcrumbs .breadcrumbs {
    width: 70%;
    padding: 0 !important;
    align-items: center;
}

.item-column-breadcrumbs {
    width: 100%;
    display: flex;
    justify-content: center;
}

.item-breadcrumbs {
    width: 100%;
    display: flex;
    justify-content: center;
}

.item-breadcrumbs .breadcrumbs {
    width: 70%;
}

.breadcrumbs ul {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
}

.breadcrumbs li:first-child {
    padding-left: 0;
}

.breadcrumbs li:first-child {
    margin-left: 0;
}

.breadcrumbs li {
    padding: 2px;
    margin: 0;
    font-size: 10px;
}

.breadcrumbs li a {
    color: gray;
}

.breadcrumbs span, .fil-Category {
    display: none;
}

.breadcrumbs li:first-child:before
{
    display: none;
    margin: 0;
    content: '';
}

.breadcrumbs li:before {
    content: "\203A" !important;
    /*content: "\f105";*/
    display: inline-block;
    /*font: normal normal normal 14px/1 FontAwesome;*/
    /*font-size: inherit;*/
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 4px;
    position: relative;
    font-size: 14px !important;
    text-align: center !important;
    color: gray !important;
}

.breadcrumbs li, .pager .pages ol {
    display: inline;
}

.hide {
    display: none !important;
}

.overlay_bg, .popup-container {
    display: none;
}

.white-space {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 110px;
    max-height: 110px;
}

.header-class-h1 {
    letter-spacing: 5px;
    line-height: 1.4;
    font-weight: 400;
    font-size: 36px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 0% 6%;
}

.mt-20px {
    margin-top: 20px;
}

.desktop-header-wrap, .mobile-header-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    position: fixed;
    transition: none;
    z-index: 999;
    top: 0;
}

.desktop-header-wrap {
    height: 110px;
}

.desktop-header {
    width: 70%;
    display: flex;
    margin: 30px 0 5px 0;
}

.mobile-header-wrap {
    display: none;
}

.desktop-header-menu-tel {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.desktop-header-tel-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;
}

.desktop-header-tel {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.header-call a, .header-phone a {
    font-size: 18px;
    text-decoration: none;
    color: #000000;
    font-weight: bolder;
    font-family: 'Open Sans',sans-serif;
}

.header-phone a {
    font-weight: normal;
    display: flex;
    align-items: center;
}

.header-phone a span:first-child {
    margin-right: 10px;
}

.header-phone>a>i  {
    margin-right: 10px;
}

.desktop-header-wrap.is-sticky {
    height: 72px;
    transition: all 1s;
}

.desktop-header-wrap.is-sticky .desktop-header-tel-wrap {
    margin-bottom: 8px;
}

.desktop-header-wrap.is-sticky .desktop-header-logo img {
    width: 125px;
    height: auto;
}

.desktop-header-wrap.is-sticky .desktop-header-tel {
    width: 35%;
}

.desktop-header-wrap.is-sticky .desktop-header {
    margin: 5px 0;
}
.desktop-header-wrap.is-sticky .desktop-header-logo {
    display: flex;
}

.desktop-header-wrap.is-sticky .desktop-header-logo a {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* menu */

.desktop-header-menu {
    width: 100%;
}

.desktop-header-menu>nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.desktop-header-menu>nav>ul {
    position: relative;
    display: flex;
    width: auto;
    margin-left: 4%;
    justify-content: space-between;
    padding-inline-start: 0;
    gap: 0 1.5rem;
}

.desktop-header-menu>nav>ul {
    list-style: none;
}

.desktop-header-menu>nav>ul>li>a {
    text-decoration: none;
    font-size: 14px;
    color: #000000;
    text-transform: uppercase;
    font-family: 'Open Sans',sans-serif;
}

.desktop-header-menu>nav>ul>li>a:hover {
    opacity: .5;
}

/* submenu 1 */
.desktop-header-menu-submenu{
    position: absolute;
    z-index: 999;
    min-width: 300px;
    background: #fff;
    list-style: none;
    padding-inline-start: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: space-between;
    min-height: 270px;
    padding: 30px 1rem 10px 1rem;
}

.desktop-header-menu>nav>ul>li:hover>.desktop-header-menu-submenu {
    visibility: visible;
}

.submenu-bottom a {
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: #000000;
    margin-left: 20px;
}

.submenu-bottom a:hover {
    opacity: .5;
}

.submenu-top {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.submenu-top-section {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
}

.submenu-top-section>a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;
    padding: 3px 0 !important;
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
    white-space: nowrap;
}

.submenu-top-section>a>span {
    color: #666;
}

.submenu-top-section>a:hover {
    opacity: .5;
}

.top-section-sprite {
    background: url('../../../../../../images/home-img/spritesheet.png') no-repeat;
    background-origin: content-box;
    height: 21px;
    width: 30px;
    display: inline-block;
}

.sprite-round {
    background-position: -5px -10px;
}

.sprite-pear {
    background-position: -5px -70px;
}

.sprite-pricess {
    background-position: -5px -103px;
}

.sprite-marquise {
    background-position: -5px -135px;
}

.sprite-oval {
    background-position: -5px -166px;
}

.sprite-radient {
    background-position: -5px -196px;
}

.sprite-emerald {
    background-position: -5px -227px;
}

.sprite-heart {
    background-position: -5px -257px;
}

.sprite-cushion {
    background-position: -5px -288px;
}

.sprite-asscher {
    background-position: -5px -41px;
}

.submenu-top-section-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.submenu-top-section-img img {
    width: 120px;
    height: 84px;
}

.submenu-top-section-big a {
    font-size: 16px
}

/* menu engagement */

.sub-m-engagement {
    left: 0%;
    visibility: hidden;
    width: fit-content;
}

.sub-img-engagement img {
    width: 80px;
    height: auto;
}

.sub-m-engagement>.submenu-top>.submenu-top-section a {
    font-size: 16px;
}

/* menu wedding */

.sub-m-wedding {
    left: 0%;
}

.sub-m-wedding .submenu-top-section {
    justify-content: flex-start;
}

.sub-m-wedding>.submenu-top>.submenu-top-section a {
    color: #666;
}

.sub-m-wedding>.submenu-top>.submenu-top-section a:hover {
    opacity: .5 !important;
}

.submenu-top-section-big {
    font-size: 16px !important;
    color: #000 !important;
}

.sub-img-wedding img {
    width: 154px;
    height: auto;
}

/* menu jewelry */

.sub-m-jewelry {
    width: fit-content;
    right: 0;
}

.sub-m-jewelry>.submenu-top>.submenu-top-section a {
    color: #666;
}

.sub-m-jewelry>.submenu-top>.submenu-top-section a:hover {
    opacity: .5 !important;
}

.sub-img-jewelry img {
    width: 154px;
    height: auto;
}

/* menu custom */

.sub-m-custom {
    width: fit-content;
    right: 0;
}

.sub-m-custom .submenu-top-section {
    justify-content: flex-start;
}

.sub-m-custom>.submenu-top>.submenu-top-section a:hover {
    opacity: .5 !important;
}

.sub-img-custom img {
    width: 115px;
    height: auto;
}

/* menu education */

.sub-m-education {
    width: fit-content;
    right: 0%;
}

.sub-m-education>.submenu-top>.submenu-top-section>a {
    color: #666;
}

.sub-img-education img {
    width: 164px;
    height: auto;
}

/* menu company */

.sub-m-company {
    width: fit-content;
    right: 0%;
}

.sub-m-company>.submenu-top>.submenu-top-section a:hover {
    opacity: .5 !important;
}

.sub-img-company img {
    width: 179px;
    height: auto;
}

.desktop-header-menu>.fa-times-circle, .mobile-menu-open>.fa-bars {
    display: none;
}

.mobile-menu-li-a {
    display: none;
}

.make-hot-call {
    display: none;
    position: relative;
    text-align: center;
    width: 100%;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    padding: 6px 0;
}

.call-link {
    display: block;
    color: #fff;
}

.call-link:hover, .call-link:focus {
    outline: 0;
    color: #fff;
    text-decoration: none;
}

.call-text {
    font-weight: 700;
    display: block;
    text-transform: uppercase;
    letter-spacing: .2em;
}

.call-link-text {
    display: block;
}

@media (max-width: 1680px) {
    .desktop-header {
        width: 75%;
    }

    .item-breadcrumbs .breadcrumbs {
        width: 75%;
    }

    .item-column-breadcrumbs .breadcrumbs {
        width: 75%;
    }
}

@media (max-width: 1440px) {
    .desktop-header-wrap.is-sticky .desktop-header-tel {
        width: 38%;
    }

    .desktop-header {
        width: 80%;
    }

    .item-breadcrumbs .breadcrumbs {
        width: 80%;
    }

    .item-column-breadcrumbs .breadcrumbs {
        width: 80%;
    }
}

@media (max-width: 1366px) {
    .desktop-header {
        width: 83%;
    }

    .item-breadcrumbs .breadcrumbs {
        width: 83%;
    }

    .item-column-breadcrumbs .breadcrumbs {
        width: 83%;
    }
}

@media (max-width: 1280px) {
    .desktop-header {
        width: 97%;
    }

    .item-breadcrumbs .breadcrumbs {
        width: 97%;
    }

    .item-column-breadcrumbs .breadcrumbs {
        width: 97%;
    }
}

@media (max-width: 1024px) {

    .desktop-header-menu>.fa-times-circle, .mobile-menu-open>.fa-bars {
        display: block;
    }


    /* mobile */

    .fa-times-circle, .fa-bars {
        display: block;
    }

    .desktop-header-menu {
        position: absolute;
        background: #fff;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: -100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s all;
    }

    .desktop-header-logo>a>img {
        width: 150px;
        height: auto;
    }

    .desktop-header-wrap {
        height: 80px;
    }

    .desktop-header {
        margin: 10px 0 0 0;
    }

    .desktop-header-tel-wrap {
        margin: 0;
        justify-content: flex-start;
    }

    .desktop-header-tel {
        width: 100%;
        flex-wrap: wrap;
    }

    .header-call, .header-phone {
        width: 100%;
    }

    .header-call {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-phone {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .header-phone>a {
        margin-right: 20px;
    }

    .burger i {
        font-size: 25px;
        cursor: pointer;
    }

    .desktop-header-menu>i {
        position: absolute;
        top: 14px;
        right: 1.2rem;
        font-size: 30px;
        cursor: pointer;
    }

    .desktop-header-menu>nav {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;
    }

    .desktop-header-menu>nav>ul {
        margin: 0;
        width: 100%;
        padding: 0 1.5rem !important;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        -webkit-transform: translateZ(0px);
        -webkit-transform: translate3d(0,0,0);
        -webkit-perspective: 1000;
        height: 80%;
    }

    .desktop-header-menu>nav>ul>li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        border-bottom: 1px solid #ebebeb;
        position: relative;
    }

    .desktop-header-menu>nav>ul>li>a {
        display: none;
    }


    .desktop-header-menu-submenu {
        position: relative;
        display: none;
        background-color: inherit;
        height: auto;
        min-width: auto;
        padding-top: 10px;
        width: 90%;
    }

    .submenu-top-section-img {
        display: none;
    }

    .submenu-bottom {
        display: flex;
        width: 100%;
    }

    .submenu-bottom a {
        margin: 0;
    }

    .submenu-top {
        flex-direction: column;
        align-items: center;
    }

    .submenu-top-section {
        width: 100%;
    }

    /*.submenu-top-section a {
        font-size: 18px;
    }*/

    .mobile-menu-li-a {
        display: block;
        position: relative;
    }

    .mobile-menu-li-a {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mobile-menu-li-a>a {
        font-size: 21px;
        text-decoration: none;
        color: #000000;
        text-transform: uppercase;
        font-family: 'Open Sans',sans-serif;
    }

    .mobile-menu-li-a>i {
        margin-left: 10px;
        font-size: 22px;
    }

    .active-mobile-accordion {
        visibility: visible;
        display: block;
        position: relative;
        height: 100%;
    }

    .mobile-menu-active {
        left: 0;
    }

}

@media (max-width: 500px) {

    .desktop-header-logo>a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .desktop-header-logo>a>img {
        width: 110px;
        height: auto;
    }

    .desktop-header-wrap.is-sticky .desktop-header-logo img {
        width: 110px;
        height: auto;
    }

    .desktop-header-menu-tel {
        justify-content: center;
    }

    .header-call a, .header-phone a  {
        font-size: 15px;
    }

    .header-phone {
        margin: 5px 5px 5px 0;
    }

    .desktop-header-menu>i {
        top: 15px;
        right: 1.2rem;
    }

    .mobile-menu-li-a>a {
        font-size: 14px;
    }

    .white-space {
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 80px;
        max-height: 80px;
    }

    .item-column-breadcrumbs .breadcrumbs, .item-breadcrumbs .breadcrumbs {
        justify-content: center;
    }

    .make-hot-call {
        display: block !important;
    }

    .desktop-block {
        display: none !important;
    }

    .desktop-header-wrap {
        flex-direction: column;
        height: 114px;
    }

    main > article > .white-space:first-child,
    .wrapper > .page > .white-space{
        height: 114px;
        max-height: 114px;
    }

    .desktop-header > div:first-child, .desktop-header > .desktop-header-menu-tel {
        flex: 1;
    }

    .desktop-header {
        margin: 16px 0;
        width: 100%;
    }
}

@media (min-width: 1920px) {
    .desktop-header-menu>nav>ul {
        gap: 0 2rem;
    }
}

@media (max-width: 1550px) {
    .desktop-header-menu>nav>ul {
        gap: 0 1rem;
    }
}

.hide {
    display: none !important;
}

/*.cms-guarantee,*/
/*.main-container {*/
/*    margin-top: 0 !important;*/
/*    top: 110px;*/
/*    position: relative;*/
/*}*/

.mt-20px {
    margin-top: 20px;
}


/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hmIqOjjg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hvIqOjjg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hnIqOjjg.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hoIqOjjg.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hkIqOjjg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hlIqOjjg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV9hrIqM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Udc1UAw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0ddc1UAw.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Vdc1UAw.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0adc1UAw.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Wdc1UAw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Xdc1UAw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Zdc0.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhmIqOjjg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhvIqOjjg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhnIqOjjg.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhoIqOjjg.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhkIqOjjg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhlIqOjjg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUdhrIqM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhmIqOjjg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhvIqOjjg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhnIqOjjg.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhoIqOjjg.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhkIqOjjg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhlIqOjjg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWiUNhrIqM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hmIqOjjg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hvIqOjjg.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hnIqOjjg.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hoIqOjjg.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hkIqOjjg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hlIqOjjg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKW-U9hrIqM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OX-hpOqc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OVuhpOqc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OXuhpOqc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OUehpOqc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OXehpOqc.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OXOhpOqc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOX-hpOqc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOVuhpOqc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOXuhpOqc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOUehpOqc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOXehpOqc.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOX-hpOqc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOVuhpOqc.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOXuhpOqc.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOUehpOqc.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOXehpOqc.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOXOhpOqc.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN8rsOUuhp.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@media (min-width: 1921px) {
    [data-trustmary-status="active"],
    .isotope-wrapper  {
        width: 1920px;
        margin: auto;
    }

    .desktop-header,
    .faq-wrap,
    .carousel_wrap,
    .catalog_description_wrap,
    .benefit-wrap,
    .eng_inline_img_wrap, 
    .main_page_map_wrap, 
    .video_wrap,
    .company_info_wrap,
    .item-breadcrumbs .breadcrumbs {
        width: 1920px !important;
    }

    .main_page_map_data {
        width: 50%;
    }

    .seo_text_wrap,
    .what_we_do_wrap,
    .faq-wrap,
    .catalog_description_wrap,
    .benefit-wrap,
    .catalog_description_wrap,
    .catalog_description_text2,
    .main_page_map_wrap,
    .company_info_wrap,
    .wrap_diamonds_content {
        padding: 0 1rem !important;
    }

    footer {
        width: 1920px !important;
        max-width: 1920px !important;
        margin: auto !important;
    }

    .footer_wrap {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 1rem !important;
    }
}